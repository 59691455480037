body {
  background: #fff8ee;
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  background-image: url('./assets/img/bestswap-bg.png');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btnLoading {
  -webkit-animation: circle 1.5s infinite linear;
}
@keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
